import React, {
  useState, useCallback, forwardRef, useImperativeHandle,
} from 'react';

import { CheckCircleTwoTone } from '@ant-design/icons';
import {
  Button, Card, Col, Row,
} from 'antd';

import api from '../../services/api';
import Modal from '../Modal';

const LetsbankInviteCheck = (_props, ref) => {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [letsbankResponse, setLetsbankResponse] = useState([]);

  const open = useCallback((cnpj, cpf, emailParams) => {
    (async () => {
      try {
        setLoading(true);
        setShowModal(true);
        const { data } = await api.get(`/letsbankIntegrations/${cnpj}/${cpf}`);
        setLetsbankResponse(data);
        setEmail(emailParams);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <Modal
      title="Resposta do Letsbank"
      visible={showModal}
      loading={loading}
      footer={(
        <Button type="primary" size="large" onClick={() => setShowModal(false)}>
          Fechar
        </Button>
      )}
      onClose={() => setShowModal(false)}
    >
      <Row justify="center" align="middle">
        <Col>
          <Card
            style={{ marginTop: '100px' }}
            bodyStyle={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <CheckCircleTwoTone
              style={{ fontSize: '80pt' }}
              twoToneColor="#52c41a"
            />
            {!loading ? (
              letsbankResponse.length ? (
                <>
                  <h3>
                    Respostas Letsbank
                    {console.log(email)}
                  </h3>
                  {letsbankResponse.map((lr) => (lr?.inviteCode ? (
                    <Row>
                      <Col>
                        {'Código do Convite - '}
                        {lr.inviteCode}
                        {' | '}
                        {'CPF - '}
                        {lr.cpf}
                        {' | '}
                        {'Status - '}
                        {lr.status}
                        {' | '}
                        {'ID da Empresa - '}
                        {lr.campaignId}
                      </Col>
                    </Row>
                  ) : lr.hash && lr.agreementId ? (
                    <Row>
                      <Col>
                        {'Hash - '}
                        {lr.hash}
                        {' | '}
                        {'Situação - '}
                        {lr.active ? 'Ativo' : 'Inativo'}
                        {' | '}
                        {'Status - '}
                        {lr.status}
                        {' | '}
                        {'ID do acordo - '}
                        {lr.agreementId}
                      </Col>
                    </Row>
                  ) : lr.branchNumber ? (
                    <Row>
                      <Col>
                        {'Número da agência - '}
                        {lr.branchNumber}
                        {' | '}
                        {'Número da conta no banco - '}
                        {lr.bankAccountNumber}
                        {' | '}
                        {'Status - '}
                        {lr.status}
                      </Col>
                    </Row>
                  ) : lr.authcode ? (
                    <Row>
                      <Col>
                        {'Código de Autentificação - '}
                        {lr.authcode}
                      </Col>
                    </Row>
                  ) : lr.data && lr.data.signupUrl ? (
                    <Row>
                      <Col>
                        {'Link - '}
                        {lr.data.signupUrl}
                      </Col>
                    </Row>
                  ) : null))}
                </>
              ) : (
                <h4>Nenhuma resposta Letsbank</h4>
              )
            ) : null}
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(LetsbankInviteCheck);
