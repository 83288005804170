import React, { useEffect, useRef } from 'react';

import {
  EditTwoTone,
  DeleteTwoTone,
  // LockOutlined
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons';
import { Space, Tooltip } from 'antd';

/* import api from '../../services/api';
import Modal from '../Password'; */
import useManager from '../../../hooks/manager';
import Table from '../../Table';
import ColumnSearch from '../../Table/Filter';
import { Action } from '../../Table/styles';
import UpdateForm from '../UpdateForm';

function UserLetsbankTable() {
  const updateRef = useRef();
  const {
    loading, datas, load, destroy, update,
  } = useManager();

  useEffect(() => {
    load('/userLetsbanks');
  }, [load]);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Nome', 'name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Email', 'email'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Status', 'status'),
    },
  ];

  /* const handleUpdate = useCallback((data) => {
    const update = async () => {
      try {
        const response = await api.put(`/userLetsbanks-pass/${id}`, data);
        const { message } = response.data;
        notification.success({
          message: 'Alterar Senha',
          description: message,
        });
        setShowModal(false);
      } catch (error) {
        if (error && error.response) {
          const { message } = error.response.data;
          if (message) {
            notification.error({
              message: 'Falha para Alterar Senha',
              description: message,
            });
            return;
          }
        }

        notification.error({
          message: 'Falha para Alterar Senha',
          description: error,
        });
      }
    };

    update();
  }, [id]); */

  return (
    <>
      <UpdateForm ref={updateRef} />
      <Table
        data={datas}
        loading={loading}
        columns={columns}
        actions={{
          title: 'Ações',
          key: 'action',
          width: 100,
          align: 'center',
          render: (text, record) => (
            <Space size="middle">
              {record.status === 'AGUARDANDO'
              || record.status === 'REPROVADO' ? (
                <>
                  <Tooltip placement="top" title="Aprovar">
                    <Action
                      onClick={() => update(record.id || record._id, '/userLetsbanks', { status: 'APROVADO' })}
                    >
                      <CheckCircleTwoTone twoToneColor="#52c41a" />
                    </Action>
                  </Tooltip>
                </>
                ) : (
                  <>
                    <Tooltip placement="top" title="Reprovar">
                      <Action
                        onClick={() => update(record.id || record._id, '/userLetsbanks', { status: 'REPROVADO' })}
                      >
                        <CloseCircleTwoTone twoToneColor="#fa8c16" />
                      </Action>
                    </Tooltip>
                  </>
                )}
              <Tooltip placement="top" title="Alterar">
                <Action
                  onClick={() => updateRef.current.open(record.id || record._id)}
                >
                  <EditTwoTone />
                </Action>
              </Tooltip>
              <Tooltip placement="top" title="Excluir">
                <Action
                  onClick={() => destroy(record.id || record._id, '/userLetsbanks')}
                >
                  <DeleteTwoTone twoToneColor="#f00" />
                </Action>
              </Tooltip>
              {/* <Tooltip placement="top" title="Alterar Senha">
                <Action onClick={() => null}>
                  <LockOutlined
                    style={{
                      color: '#fadb14',
                    }}
                  />
                </Action>
              </Tooltip> */}
            </Space>
          ),
        }}
      />
    </>
  );
}

export default UserLetsbankTable;
