import React, {
  useState, useEffect, useCallback, forwardRef, useRef,
} from 'react';
import { useParams } from 'react-router-dom';

import { CheckCircleTwoTone } from '@ant-design/icons';
import {
  Form, Input, Button, notification, Tabs, Spin, Row, Col, Card,
} from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { validateCNPJ, validateCPF, validatePhone } from 'validations-br';
import * as Yup from 'yup';

import athosLetsbankImg from '../../assets/athos-letsbank.png';
import Modal from '../../components/Modal';
import api from '../../services/api';
import cep from '../../services/cep';

const LETSBANK_CNPJ = '@letsbank_cnpj';
const LETSBANK_CPF = '@letsbank_cpf';
const LETSBANK_EMAIL = '@letsbank_email';
const LETSBANK_SUCCESS = '@letsbank_success';

const CompanyLetsbank = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [successCompany, setSuccessCompany] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [token, setToken] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [link, setLink] = useState('');
  const [loadingSearchZipCode, setLoadingSearchZipCode] = useState(false);
  const formRef = useRef();
  const params = useParams();

  useEffect(() => {
    setToken(params.token);
  }, [params.token]);

  useEffect(() => {
    const cnpjStorage = localStorage.getItem(LETSBANK_CNPJ);
    const cpfStorage = localStorage.getItem(LETSBANK_CPF);
    let letsbankSuccess = localStorage.getItem(LETSBANK_SUCCESS);
    setCnpj(cnpjStorage);
    setCpf(cpfStorage);

    if (cnpjStorage) {
      setSuccessCompany(true);
    }

    if (letsbankSuccess) {
      letsbankSuccess = JSON.parse(letsbankSuccess);
      setLink(letsbankSuccess.data.signupUrl);
      setSuccess(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = {
          cnpj: cnpj ? cnpj.replace(/[^\d]+/g, '') : '',
          cpf: cpf ? cpf.replace(/[^\d]+/g, '') : '',
        };

        if (data.cnpj.length === 14 && data.cpf.length === 11) {
          setLoading(true);
          const response = await api.get(
            `/companies/letsbank/${token}/${data.cnpj}/${data.cpf}`,
          );
          // console.log(response.data);
          const { company, letsbankInvite, link: linkData } = response.data;
          localStorage.setItem(LETSBANK_CNPJ, company.cnpj);
          localStorage.setItem(LETSBANK_CPF, company.cpf);
          localStorage.setItem(LETSBANK_EMAIL, company.email);
          setLink(linkData ? linkData.data.signupUrl : '');
          setLoading(false);
          setShowModal(false);
          setSuccessCompany(true);

          if (letsbankInvite) {
            setSuccess(true);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        localStorage.removeItem(LETSBANK_CNPJ);
        localStorage.removeItem(LETSBANK_CPF);
        localStorage.removeItem(LETSBANK_EMAIL);
        setSuccess(false);
        setSuccessCompany(false);
        console.log(error);
      }
    })();
  }, [token, cnpj, cpf]);

  const searchZipCode = useCallback((zipCode) => {
    if (zipCode.length < 8) {
      formRef.current.setFieldsValue({ street: '' });
      formRef.current.setFieldsValue({ neighborhood: '' });
      formRef.current.setFieldsValue({ city: '' });
      formRef.current.setFieldsValue({ state: '' });
      return;
    }

    (async () => {
      try {
        setLoadingSearchZipCode(true);
        const { data } = await cep.get(`/${zipCode}/json`);
        formRef.current.setFieldsValue({ street: data.logradouro });
        formRef.current.setFieldsValue({ neighborhood: data.bairro });
        formRef.current.setFieldsValue({ city: data.localidade });
        formRef.current.setFieldsValue({ state: data.uf });
        console.log(data);
        setLoadingSearchZipCode(false);
      } catch (err) {
        setLoadingSearchZipCode(false);
        formRef.current.setFieldsValue({ street: '' });
        formRef.current.setFieldsValue({ neighborhood: '' });
        formRef.current.setFieldsValue({ city: '' });
        formRef.current.setFieldsValue({ state: '' });
      }
    })();
  }, []);

  const handleSubmit = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        let data = formRef.current.getFieldsValue();

        data = {
          ...data,
          cnpj: cnpj ? cnpj.replace(/[^\d]+/g, '') : '',
          cpf: cpf ? cpf.replace(/[^\d]+/g, '') : '',
          phone: phone ? phone.replace(/[^\d]+/g, '') : '',
        };

        const schema = Yup.object().shape({
          cnpj: Yup.string().required('Informe o CNPJ'),
          cpf: Yup.string().required('Informe o CPF'),
          name: Yup.string().required('Informe o Nome'),
          fantasyName: Yup.string().required('Informe o Nome Fantasia'),
          email: Yup.string().email().required('Informe o Email'),
          phone: Yup.string().required('Informe o Celular'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const isValidCNPJ = validateCNPJ(cnpj);

        if (!isValidCNPJ) {
          setValidationErrors({ cnpj: 'CNPJ inválido' });
          setLoading(false);
          return;
        }

        const isValidCPF = validateCPF(cpf);

        if (!isValidCPF) {
          setValidationErrors({ cpf: 'CPF inválido' });
          setLoading(false);
          return;
        }

        const isValidPhone = validatePhone(phone);

        if (!isValidPhone) {
          setValidationErrors({ phone: 'Celular inválido' });
          setLoading(false);
          return;
        }

        // validation passed
        const response = await api.post(`/companies/letsbank/${token}`, data);
        const { company } = response.data;
        localStorage.setItem(LETSBANK_CNPJ, company.cnpj);
        localStorage.setItem(LETSBANK_CPF, company.cpf);
        localStorage.setItem(LETSBANK_EMAIL, company.email);
        setCnpj(company.cnpj);
        setCpf(company.cpf);
        formRef.current.resetFields();
        setValidationErrors({});
        setLoading(false);
        setShowModal(false);
        setSuccessCompany(true);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notification.error({
            message: 'Informe os campos necessários para cadastro',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          console.log(ve);
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notification.error({
            message,
          });

          if (err.response.status === 400) {
            const { company } = err.response.data;
            if (company) {
              localStorage.setItem(LETSBANK_CNPJ, company.cnpj);
              localStorage.setItem(LETSBANK_CPF, company.cpf);
              localStorage.setItem(LETSBANK_EMAIL, company.email);
              setCnpj(company.cnpj);
              setCpf(company.cpf);
              formRef.current.resetFields();
              setShowModal(false);
              setSuccessCompany(true);
            }
          }

          return;
        }

        notification.error({
          message: 'Falha ao cadastrar',
        });
      }
    })();
  },
  [token, cnpj, cpf, phone]);

  const handleInvite = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.post(`/companies/letsbank/invite/${token}`, { cnpj, cpf });
        setSuccess(true);
        localStorage.setItem(LETSBANK_SUCCESS, JSON.stringify(data));
        setLink(data.data.signupUrl);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notification.error({
            message,
          });

          return;
        }

        notification.error({
          message: 'Falha ao solicitar convite',
        });
      }
    })();
  }, [token, cnpj, cpf]);

  if (success) {
    return (
      <>
        <Row justify="center" align="middle">
          <Col>
            <Card
              style={{ marginTop: '100px' }}
              bodyStyle={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <CheckCircleTwoTone
                style={{ fontSize: '80pt' }}
                twoToneColor="#52c41a"
              />
              <h3 style={{ marginTop: '20px' }}>
                Link solicitado com sucesso.
              </h3>
              <a href={link} target="_blank" rel="noopener noreferrer">
                <Button type="primary" size="large">
                  Clique aqui para continuar o cadastro letsbank
                </Button>
              </a>
            </Card>
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col>
            <img src={athosLetsbankImg} alt="Athos Letsbank" />
          </Col>
        </Row>
      </>
    );
  }

  if (successCompany) {
    return (
      <>
        <Row justify="center" align="middle">
          <Col>
            <Card
              style={{ marginTop: '100px' }}
              bodyStyle={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <CheckCircleTwoTone
                style={{ fontSize: '80pt' }}
                twoToneColor="#52c41a"
              />
              <h3 style={{ marginTop: '20px' }}>
                Empresa cadastrada com sucesso.
              </h3>
              <Button type="primary" size="large" onClick={handleInvite}>
                Solicitar Link para continuar cadastro
              </Button>
            </Card>
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col>
            <img src={athosLetsbankImg} alt="Athos Letsbank" />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Modal
      title="Cadastro Letsbank"
      visible={showModal}
      loading={loading}
      footer={(
        <Button type="primary" size="large" onClick={handleSubmit}>
          Cadastrar
        </Button>
      )}
    >
      <Form ref={formRef} size="large">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Empresa" key="1">
            <Form.Item
              validateStatus={validationErrors.cnpj ? 'error' : ''}
              help={validationErrors.cnpj}
              hasFeedback
            >
              <MaskedInput
                mask="11.111.111/1111-11"
                placeholder="CNPJ da empresa"
                value={cnpj}
                onChange={({ target: { value } }) => setCnpj(value)}
              />
            </Form.Item>
            <Form.Item
              validateStatus={validationErrors.cpf ? 'error' : ''}
              help={validationErrors.cpf}
              hasFeedback
            >
              <MaskedInput
                mask="111.111.111-11"
                placeholder="CPF do representante legal da empresa"
                value={cpf}
                onChange={({ target: { value } }) => setCpf(value)}
              />
            </Form.Item>
            <Form.Item
              name="name"
              validateStatus={validationErrors.name ? 'error' : ''}
              help={validationErrors.name}
              hasFeedback
            >
              <Input
                placeholder="Nome completo do representante legal da empresa"
                maxLength="50"
              />
            </Form.Item>
            <Form.Item
              name="fantasyName"
              validateStatus={validationErrors.fantasyName ? 'error' : ''}
              help={validationErrors.fantasyName}
              hasFeedback
            >
              <Input placeholder="Nome Fantasia" />
            </Form.Item>
            <Form.Item
              name="email"
              validateStatus={validationErrors.email ? 'error' : ''}
              help={validationErrors.email}
              hasFeedback
            >
              <Input
                placeholder="Email do representante legal da empresa"
                maxLength="50"
              />
            </Form.Item>
            <Form.Item
              name="phone"
              validateStatus={validationErrors.phone ? 'error' : ''}
              help={validationErrors.phone}
              hasFeedback
            >
              <MaskedInput
                mask="(11) 11111-1111"
                placeholder="Celular do representante legal da empresa"
                value={phone}
                onChange={({ target: { value } }) => setPhone(value)}
              />
            </Form.Item>
            <Row justify="center" align="middle">
              <Col>
                <img
                  src={athosLetsbankImg}
                  alt="Athos Letsbank"
                  style={{
                    width: '100%',
                    maxWidth: '400px',
                  }}
                />
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Endereço (Opcional)" key="2" forceRender>
            <Form.Item name="zipCode">
              <Input
                placeholder="CEP"
                onChange={(e) => searchZipCode(e.target.value)}
              />
            </Form.Item>
            <Spin spinning={loadingSearchZipCode}>
              <Form.Item name="street">
                <Input placeholder="Logradouro" />
              </Form.Item>
              <Form.Item name="number">
                <Input placeholder="Número" />
              </Form.Item>
              <Form.Item name="complement">
                <Input placeholder="Complemento" />
              </Form.Item>
              <Form.Item name="neighborhood">
                <Input placeholder="Bairro" />
              </Form.Item>
              <Form.Item name="city">
                <Input placeholder="Cidade" />
              </Form.Item>
              <Form.Item name="state">
                <Input placeholder="Estado" />
              </Form.Item>
            </Spin>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default forwardRef(CompanyLetsbank);
