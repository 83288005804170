import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #f1f1f1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LoginContainer = styled.div`
  padding: 100px 70px 70px 70px;
  margin: 10px;
  width: 100%;
  max-width: 500px;
  min-height: 200px;
  border-radius: 0.25rem;
  background-color: #7831af;
  opacity: 0.9;
`;

export default Container;
