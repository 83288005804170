import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  BankOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';

import { AuthContext } from '../../contexts/auth';

const { Sider } = Layout;

export default function MenuLeft({ selectedKey }) {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const { userLetsbankSignOut } = useContext(AuthContext);

  const handleCollapse = () => {
    setCollapsed((oldstate) => (!oldstate));
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={handleCollapse}
      width="250px"
    >
      <Menu theme="dark" defaultSelectedKeys={[selectedKey]} mode="inline">
        <Menu.Item
          key="0"
          icon={<BankOutlined />}
          onClick={() => navigate('/empresas')}
        >
          Empresas
        </Menu.Item>
        <Menu.Item
          key="1"
          icon={<LogoutOutlined />}
          onClick={() => userLetsbankSignOut()}
        >
          Sair
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
