import React from 'react';

import Table from '../../components/UserLetsbankCompany/Table';
import Page from '../../layoutUserLetsbank/Page';

function Company() {
  return (
    <Page title="Empresas" selectedKey="0">
      <Table />
    </Page>
  );
}

export default Company;
