import React, {
  useState, useCallback, forwardRef, useImperativeHandle, useRef,
} from 'react';

import {
  Form, Input, Button, notification, Checkbox, Row, Col,
} from 'antd';
import * as Yup from 'yup';

import useManager from '../../../hooks/manager';
import api from '../../../services/api';
import Modal from '../../Modal';

const UpdateForm = (_props, ref) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [useToken, setUseToken] = useState(false);
  const [useUserName, setUseUserName] = useState(false);
  const [usePassword, setUsePassword] = useState(false);
  const [useClientID, setUseClientID] = useState(false);
  const [useClientSecret, setUseClientSecret] = useState(false);
  const { update } = useManager();
  const formRef = useRef();

  const open = useCallback((openId) => {
    (async () => {
      try {
        setLoading(true);
        setShowModal(true);
        const { data } = await api.get(`/appintegration/${openId}`);
        setId(openId);
        formRef.current.setFieldsValue({ name: data.name });
        setUseToken(data.useToken);
        setUseUserName(data.useUserName);
        setUsePassword(data.usePassword);
        setUseClientID(data.useClientID);
        setUseClientSecret(data.useClientSecret);
        formRef.current.setFieldsValue({ defaultToken: data.defaultToken });
        formRef.current.setFieldsValue({ defaultUserName: data.defaultUserName });
        formRef.current.setFieldsValue({ defaultPassword: data.defaultPassword });
        formRef.current.setFieldsValue({ defaultClientID: data.defaultClientID });
        formRef.current.setFieldsValue({ defaultClientSecret: data.defaultClientSecret });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [formRef]);

  useImperativeHandle(ref, () => ({
    open,
  }));

  const handleSubmit = useCallback(() => {
    (async () => {
      try {
        const data = {
          ...formRef.current.getFieldsValue(),
          useToken,
          useUserName,
          usePassword,
          useClientID,
          useClientSecret,
        };

        console.log(data);

        const schema = Yup.object().shape({
          name: Yup.string().required('Informe o nome'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        update(id, '/appintegration', data, () => {
          formRef.current.resetFields();
          setValidationErrors({});
          setShowModal(false);
        });
      } catch (err) {
        console.log(err);
        // Validation failed
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notification.error({
            message: 'Falha na Validação',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notification.error({
            message,
          });

          return;
        }

        notification.error({
          message: 'Falha ao salvar',
        });
      }
    })();
  }, [
    update,
    id,
    useToken,
    useUserName,
    usePassword,
    useClientID,
    useClientSecret,
  ]);

  return (
    <Modal
      title="Alterar Nome do App de Integração"
      visible={showModal}
      loading={loading}
      footer={(
        <Button type="primary" size="large" onClick={handleSubmit}>
          Alterar
        </Button>
      )}
      onClose={() => setShowModal(false)}
    >
      <Form ref={formRef} size="large">
        <Form.Item
          name="name"
          validateStatus={validationErrors.name ? 'error' : ''}
          help={validationErrors.name}
          hasFeedback
        >
          <Input placeholder="Nome" />
        </Form.Item>
        <Row>
          <Col xs={6}>
            <Form.Item name="useToken">
              <Checkbox
                checked={useToken}
                onChange={() => setUseToken((prevState) => !prevState)}
              >
                Utiliza Token
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={12}>
            {useToken ? (
              <Form.Item name="defaultToken">
                <Input placeholder="Valor Default" />
              </Form.Item>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Item name="useUserName">
              <Checkbox
                checked={useUserName}
                onChange={() => setUseUserName((prevState) => !prevState)}
              >
                Utiliza Nome de Usuário
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={12}>
            {useUserName ? (
              <Form.Item name="defaultUserName">
                <Input placeholder="Valor Default" />
              </Form.Item>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Item name="usePassword">
              <Checkbox
                checked={usePassword}
                onChange={() => setUsePassword((prevState) => !prevState)}
              >
                Utiliza Senha
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={12}>
            {usePassword ? (
              <Form.Item name="defaultPassword">
                <Input placeholder="Valor Default" />
              </Form.Item>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Item name="useClientID">
              <Checkbox
                checked={useClientID}
                onChange={() => setUseClientID((prevState) => !prevState)}
              >
                Utiliza Cliente ID
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={12}>
            {useClientID ? (
              <Form.Item name="defaultClientID">
                <Input placeholder="Valor Default" />
              </Form.Item>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Item name="useClientSecret">
              <Checkbox
                checked={useClientSecret}
                onChange={() => setUseClientSecret((prevState) => !prevState)}
              >
                Utiliza Cliente Secret
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={12}>
            {useClientSecret ? (
              <Form.Item name="defaultClientSecret">
                <Input placeholder="Valor Default" />
              </Form.Item>
            ) : null}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default forwardRef(UpdateForm);
