import React from 'react';

import { Modal as AntdModal, Spin } from 'antd';

import { Content } from './styles';

export default function Modal({
  title,
  visible,
  loading,
  children,
  onClose,
  ...props
}) {
  return (
    <AntdModal
      title={title}
      visible={visible}
      width="80%"
      onCancel={onClose}
      centered
      {...props}
    >
      <Spin spinning={loading}>
        <Content>{children}</Content>
      </Spin>
    </AntdModal>
  );
}
