/* eslint-disable no-underscore-dangle */
import React from 'react';

import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { Table, Space, Tooltip } from 'antd';

import ColumnSearch from './Filter';
import { Action } from './styles';

function TableComponent({
  data = [],
  hasActionUpdate,
  hasActionDelete,
  onOpen,
  onDelete,
  columns = [
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
      render: (text) => (
        { text }
      ),
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Título', 'title'),
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Descrição', 'description'),
    },
  ],
  hasAction = true,
  actions = {
    title: 'Ações',
    key: 'action',
    width: 100,
    align: 'center',
    render: (text, record) => (
      <Space size="middle">
        {
          hasActionUpdate && (
            <Tooltip placement="top" title="Alterar">
              <Action onClick={() => onOpen(record.id || record._id)}>
                <EditTwoTone />
              </Action>
            </Tooltip>
          )
        }
        {
          hasActionDelete && (
            <Tooltip placement="top" title="Excluir">
              <Action onClick={() => onDelete(record.id || record._id)}>
                <DeleteTwoTone twoToneColor="#f00" />
              </Action>
            </Tooltip>
          )
        }
      </Space>
    ),
  },
  selectedRowKeys,
  onSelectChange,
  ...props
}) {
  if (hasAction) {
    columns.push(actions);
  }

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => (record.id || record._id)}
      /* rowSelection={{
        selectedRowKeys,
        onChange: onSelectChange,
        hideDefaultSelections: true,
        selections: [
          Table.SELECTION_ALL,
          Table.SELECTION_INVERT,
        ],
      }} */
      showSorterTooltip={false}
      bordered
      {...props}
    />
  );
}

export default TableComponent;
