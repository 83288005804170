import React from 'react';

import { Layout } from 'antd';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Menu from '../../components/UserLetsbankMenuLeft';
import Content from './styles';

export default function Page({
  title,
  subTitle,
  description,
  selectedKey,
  children,
}) {
  return (
    <Layout style={{ minHeight: '100vh' }}>

      <Menu selectedKey={selectedKey} />

      <Layout>
        <Header
          title={title}
          subTitle={subTitle}
          content={description}
        />

        <Content>
          {children}
        </Content>

        <Footer />
      </Layout>
    </Layout>
  );
}
