import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  BankOutlined,
  DesktopOutlined,
  HomeOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';

import { AuthContext } from '../../contexts/auth';

const { Sider } = Layout;

export default function MenuLeft({ selectedKey }) {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const { signOut } = useContext(AuthContext);

  const handleCollapse = () => {
    setCollapsed((oldstate) => (!oldstate));
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={handleCollapse}
      width="250px"
    >
      <Menu theme="dark" defaultSelectedKeys={[selectedKey]} mode="inline">
        <Menu.Item
          key="0"
          icon={<HomeOutlined />}
          onClick={() => navigate('/')}
        >
          Home
        </Menu.Item>
        <Menu.Item
          key="1"
          icon={<DesktopOutlined />}
          onClick={() => navigate('/administradores')}
        >
          Administradores
        </Menu.Item>
        <Menu.Item
          key="6"
          icon={<UsergroupAddOutlined />}
          onClick={() => navigate('/usuarios-letsbank')}
        >
          Usuários Letsbank
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<BankOutlined />}
          onClick={() => navigate('/empresas')}
        >
          Empresas
        </Menu.Item>
        <Menu.Item
          key="3"
          icon={<AppstoreAddOutlined />}
          onClick={() => navigate('/appintegracao')}
        >
          Apps de Integrações
        </Menu.Item>
        <Menu.Item
          key="4"
          icon={<AppstoreOutlined />}
          onClick={() => navigate('/integracoes')}
        >
          Integração das Aplicações
        </Menu.Item>
        <Menu.Item key="5" icon={<LogoutOutlined />} onClick={() => signOut()}>
          Sair
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
