import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

import { Card, Spin } from 'antd';

import Page from '../../layout/Page';
import api from '../../services/api';

function Home() {
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data = [] } = await api.get('/integrationByAppIntegrationTotals');
        setDatas(data.map((d) => d.count));
        setCategories(data.map((d) => d.name));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, []);

  return (
    <Page title="Gerenciador de Integrações" selectedKey="0">
      <Card title="Integrações ativas por App de Integração">
        <Spin spinning={loading}>
          <ReactApexChart
            options={{
              chart: {
                height: 350,
                type: 'bar',
                /* events: {
                click(chart, w, e) {
                  console.log(chart, w, e);
                },
              }, */
              },
              // colors,
              plotOptions: {
                bar: {
                  columnWidth: '45%',
                  distributed: true,
                },
              },
              dataLabels: {
                enabled: false,
              },
              legend: {
                show: false,
              },
              xaxis: {
                categories,
                labels: {
                  style: {
                    // colors,
                    fontSize: '12px',
                  },
                },
              },
            }}
            series={[
              {
                data: datas,
              },
            ]}
            type="bar"
            height={350}
          />
        </Spin>
      </Card>
    </Page>
  );
}

export default Home;
