import React from 'react';
import { Routes, Route } from 'react-router-dom';

import CompanyLetsbank from '../pages/CompanyLetsbank';
import CompanyNew from '../pages/CompanyNew';
import SignIn from '../pages/SignIn';
import UserLetsbankSignIn from '../pages/UserLetsbankSignIn';

export default () => (
  <Routes>
    <Route path="/" element={<SignIn />} />
    <Route path="/login-usuario-letsbank" element={<UserLetsbankSignIn />} />
    <Route path="/empresas-cadastro/:token" element={<CompanyNew />} />
    <Route path="/letsbank/invite/:token" element={<CompanyLetsbank />} />
  </Routes>
);
