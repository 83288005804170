import React, { useEffect, useState } from 'react';

import { Row } from 'antd';

import userImgDefault from '../../assets/user.png';
import useAuth from '../../hooks/auth';
import api from '../../services/api';
import { getIdKey } from '../../services/auth';
import { getIdKey as userLetsbankIdkey } from '../../services/userletsbankauth';
import { Header, Thumbnail, ThumbnailContainer } from './styles';

export default function HeaderComponent({
  title,
  subTitle,
  content,
  onBack,
}) {
  const [user, setUser] = useState({});
  const { isAuthenticated, isUserLetsbankAuthenticated } = useAuth();

  useEffect(() => {
    const id = getIdKey() || userLetsbankIdkey();
    const loadUser = async () => {
      let res;
      if (isAuthenticated()) {
        res = await api.get(`/adm/${id}`);
      }

      if (isUserLetsbankAuthenticated()) {
        res = await api.get(`/userLetsbankAuth/${id}`);
      }

      if (res.data) {
        setUser(res.data);
      }
    };

    loadUser();
  }, [isAuthenticated, isUserLetsbankAuthenticated]);

  return (
    <Header
      ghost={false}
      title={title}
      subTitle={subTitle}
      onBack={onBack}
    >
      <Row>
        <div style={{ flex: 1 }}>{content}</div>
        <ThumbnailContainer>
          <Thumbnail
            src={user.thumbnail ? user.thumbnail_url : userImgDefault}
          />
          <strong>{user.name}</strong>
        </ThumbnailContainer>
      </Row>
    </Header>
  );
}
