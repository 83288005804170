import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import {
  Form,
  Input,
  Button,
  notification,
  Select,
  Spin,
  Checkbox,
} from 'antd';
import * as Yup from 'yup';

import useManager from '../../../hooks/manager';
import api from '../../../services/api';
import Modal from '../../Modal';

const NewForm = (_props, ref) => {
  const [appIntegrations, setAppIntegrations] = useState([]);
  const [appIntegration, setAppIntegration] = useState();
  const [appIntegrationData, setAppIntegrationData] = useState({});
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState();
  const [token, setToken] = useState('');
  const [useLocalTunnel, setUseLocalTunnel] = useState(false);
  const [useLocalUrl, setUseLocalUrl] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingToken, setLoadingToken] = useState(false);
  const { store } = useManager();
  const formRef = useRef();

  useImperativeHandle(ref, () => ({
    open: async () => {
      try {
        setLoading(true);
        setLoadingToken(!company);
        const { data: appIntegrationsData } = await api.get('/appintegration');
        setAppIntegrations(appIntegrationsData);

        const { data: companiesData } = await api.get('/company');
        setCompanies(companiesData);
        setShowModal(true);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        notification.error({
          message: 'Falha para carregar as lista de empresas e/ou os app de integração',
        });
      }
    },
  }));

  const generateToken = useCallback(() => {
    (async () => {
      try {
        if (!company) {
          notification.warn({
            message: 'Selecione uma empresa',
          });
          return;
        }
        setLoadingToken(true);
        const { data } = await api.get(`/token/${company}`);
        setToken(data);
        setLoadingToken(false);
      } catch (error) {
        setLoadingToken(false);
        notification.error({
          message: 'Falha para gerar token',
        });
      }
    })();
  }, [company]);

  useEffect(() => {
    if (company) {
      generateToken();
    }
  }, [company, generateToken]);

  const handleSubmit = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        let data = {
          ...formRef.current.getFieldsValue(),
          token,
          useLocalTunnel,
          useLocalUrl,
          appIntegration,
          company,
        };

        data = {
          ...data,
          url: !useLocalUrl && !useLocalTunnel ? data.url : '',
        };

        console.log(data);

        const schema = Yup.object().shape({
          url:
            useLocalTunnel || useLocalUrl
              ? null
              : Yup.string().url('URL inválida').required('Informe a url'),
          urlAppIntegration: Yup.string()
            .url('URL inválida')
            .required('Informe a url da integração'),
          token: Yup.string().required('Gere um token'),
          appIntegration: Yup.string().required('Escolha um app de integração'),
          company: Yup.string().required('Escolha uma Empresa'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        store('/integration', data, () => {
          formRef.current.resetFields();
          setValidationErrors({});
          setToken(null);
          setCompany(null);
          setAppIntegration(null);
          setUseLocalTunnel(false);
          setShowModal(false);
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notification.error({
            message: 'Falha na Validação',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notification.error({
            message,
          });

          return;
        }

        notification.error({
          message: 'Falha ao cadastrar',
        });
      }
    })();
  }, [store, appIntegration, company, token, useLocalTunnel, useLocalUrl]);

  const handleAppIntegration = useCallback(
    (value) => {
      setAppIntegration(value);
      const selectedData = appIntegrations.find((a) => a._id === value);
      setAppIntegrationData(selectedData);
      if (selectedData.useToken) {
        formRef.current.setFieldsValue({ tokenAppIntegration: selectedData.defaultToken });
      }

      if (selectedData.useUserName) {
        formRef.current.setFieldsValue({ userAppIntegration: selectedData.defaultUserName });
      }

      if (selectedData.usePassword) {
        formRef.current.setFieldsValue({ passwordAppIntegration: selectedData.defaultPassword });
      }

      if (selectedData.useClientID) {
        formRef.current.setFieldsValue({ clientIDAppIntegration: selectedData.defaultClientID });
      }

      if (selectedData.useClientSecret) {
        formRef.current.setFieldsValue({
          clientSecretAppIntegration: selectedData.defaultClientSecret,
        });
      }
    },
    [appIntegrations],
  );

  return (
    <Modal
      title="Novo Integração"
      visible={showModal}
      loading={loading}
      footer={(
        <Button type="primary" size="large" onClick={handleSubmit}>
          Cadastrar
        </Button>
      )}
      onClose={() => setShowModal(false)}
    >
      <Form ref={formRef} size="large">
        <Form.Item name="useLocalTunnel">
          <Checkbox
            checked={useLocalTunnel}
            onChange={() => setUseLocalTunnel((prevState) => {
              setUseLocalUrl(false);
              return !prevState;
            })}
          >
            Usar Local Tunnel (Url publica será gerada no start do servidor)
          </Checkbox>
        </Form.Item>
        <Form.Item name="useLocalUrl">
          <Checkbox
            checked={useLocalUrl}
            onChange={() => setUseLocalUrl((prevState) => {
              setUseLocalTunnel(false);
              return !prevState;
            })}
          >
            Usar Local URL (Url da rede local do servidor será gerada no start
            do servidor)
          </Checkbox>
        </Form.Item>
        {!useLocalUrl && !useLocalTunnel ? (
          <Form.Item
            name="url"
            validateStatus={validationErrors.url ? 'error' : ''}
            help={validationErrors.url}
            hasFeedback
          >
            <Input placeholder="Url API" readOnly={useLocalTunnel} />
          </Form.Item>
        ) : null}
        <Form.Item
          name="urlAppIntegration"
          validateStatus={validationErrors.urlAppIntegration ? 'error' : ''}
          help={validationErrors.urlAppIntegration}
          hasFeedback
        >
          <Input placeholder="Url API da Integração" />
        </Form.Item>
        <Form.Item
          validateStatus={validationErrors.appIntegration ? 'error' : ''}
          help={validationErrors.appIntegration}
          hasFeedback
        >
          <Select
            placeholder="App de Integração"
            value={appIntegration}
            onChange={handleAppIntegration}
          >
            {appIntegrations.map((a) => (
              <Select.Option key={a._id} value={a._id}>
                {a.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {appIntegrationData?.useToken && (
          <Form.Item
            name="tokenAppIntegration"
            validateStatus={validationErrors.tokenAppIntegration ? 'error' : ''}
            help={validationErrors.tokenAppIntegration}
            hasFeedback
          >
            <Input placeholder="Token ou Chave da Integração" />
          </Form.Item>
        )}
        {appIntegrationData?.useClientID && (
          <Form.Item
            name="clientIDAppIntegration"
            validateStatus={
              validationErrors.clientIDAppIntegration ? 'error' : ''
            }
            help={validationErrors.clientIDAppIntegration}
            hasFeedback
          >
            <Input placeholder="Cliente ID da Integração" />
          </Form.Item>
        )}
        {appIntegrationData?.useClientSecret && (
          <Form.Item
            name="clientSecretAppIntegration"
            validateStatus={
              validationErrors.clientSecretAppIntegration ? 'error' : ''
            }
            help={validationErrors.clientSecretAppIntegration}
            hasFeedback
          >
            <Input placeholder="Cliente Secret da Integração" />
          </Form.Item>
        )}
        {appIntegrationData?.useUserName && (
          <Form.Item
            name="userAppIntegration"
            validateStatus={validationErrors.userAppIntegration ? 'error' : ''}
            help={validationErrors.userAppIntegration}
            hasFeedback
          >
            <Input placeholder="Usuário da Integração" />
          </Form.Item>
        )}
        {appIntegrationData?.usePassword && (
          <Form.Item
            name="passwordAppIntegration"
            validateStatus={
              validationErrors.passwordAppIntegration ? 'error' : ''
            }
            help={validationErrors.passwordAppIntegration}
            hasFeedback
          >
            <Input placeholder="Senha da Integração" />
          </Form.Item>
        )}
        <Form.Item
          validateStatus={validationErrors.company ? 'error' : ''}
          help={validationErrors.company}
          hasFeedback
        >
          <Select
            placeholder="Empresa"
            value={company}
            onChange={(value) => setCompany(value)}
          >
            {companies.map((c) => (
              <Select.Option key={c._id} value={c._id}>
                {c.fantasyName}
                {' - '}
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Spin
          tip={!company ? 'Selecione uma empresa' : null}
          spinning={loadingToken}
        >
          <Form.Item
            validateStatus={validationErrors.token ? 'error' : ''}
            help={validationErrors.token}
            hasFeedback
          >
            <Input
              value={token}
              placeholder="Token"
              addonAfter={(
                <Button type="primary" size="middle" onClick={generateToken}>
                  Gerar Token
                </Button>
              )}
              readOnly
            />
          </Form.Item>
        </Spin>
      </Form>
    </Modal>
  );
};

export default forwardRef(NewForm);
