import React, {
  useState, useCallback, forwardRef, useImperativeHandle, useRef,
} from 'react';

import {
  Form, Input, Button, notification, Tabs, Spin,
} from 'antd';
import * as Yup from 'yup';

import useManager from '../../../hooks/manager';
import cep from '../../../services/cep';
import Modal from '../../Modal';

const NewForm = (_props, ref) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSearchZipCode, setLoadingSearchZipCode] = useState(false);
  const { store } = useManager();
  const formRef = useRef();

  useImperativeHandle(ref, () => ({
    open: () => setShowModal(true),
  }));

  const searchZipCode = useCallback((zipCode) => {
    if (zipCode.length < 8) {
      formRef.current.setFieldsValue({ street: '' });
      formRef.current.setFieldsValue({ neighborhood: '' });
      formRef.current.setFieldsValue({ city: '' });
      formRef.current.setFieldsValue({ state: '' });
      return;
    }

    (async () => {
      try {
        setLoadingSearchZipCode(true);
        const { data } = await cep.get(`/${zipCode}/json`);
        formRef.current.setFieldsValue({ street: data.logradouro });
        formRef.current.setFieldsValue({ neighborhood: data.bairro });
        formRef.current.setFieldsValue({ city: data.localidade });
        formRef.current.setFieldsValue({ state: data.uf });
        console.log(data);
        setLoadingSearchZipCode(false);
      } catch (err) {
        setLoadingSearchZipCode(false);
        formRef.current.setFieldsValue({ street: '' });
        formRef.current.setFieldsValue({ neighborhood: '' });
        formRef.current.setFieldsValue({ city: '' });
        formRef.current.setFieldsValue({ state: '' });
      }
    })();
  }, []);

  const handleSubmit = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const data = formRef.current.getFieldsValue();

        const schema = Yup.object().shape({
          cnpj: Yup.string().required('Informe o CNPJ'),
          name: Yup.string().required('Informe o Nome'),
          fantasyName: Yup.string().required('Informe o Nome Fantasia'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        store('/company', data, () => {
          formRef.current.resetFields();
          setValidationErrors({});
          setShowModal(false);
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notification.error({
            message: 'Falha na Validação',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notification.error({
            message,
          });

          return;
        }

        notification.error({
          message: 'Falha ao cadastrar',
        });
      }
    })();
  },
  [store]);

  return (
    <Modal
      title="Nova Empresa"
      visible={showModal}
      loading={loading}
      footer={(
        <Button type="primary" size="large" onClick={handleSubmit}>
          Cadastrar
        </Button>
      )}
      onClose={() => setShowModal(false)}
    >
      <Form ref={formRef} size="large">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Empresa" key="1">
            <Form.Item
              name="cnpj"
              validateStatus={validationErrors.cnpj ? 'error' : ''}
              help={validationErrors.cnpj}
              hasFeedback
            >
              <Input placeholder="CNPJ da empresa" maxLength={14} />
            </Form.Item>
            <Form.Item name="cpf">
              <Input
                placeholder="CPF do representante legal da empresa"
                maxLength={11}
              />
            </Form.Item>
            <Form.Item
              name="name"
              validateStatus={validationErrors.name ? 'error' : ''}
              help={validationErrors.name}
              hasFeedback
            >
              <Input placeholder="Nome completo do representante legal da empresa" />
            </Form.Item>
            <Form.Item
              name="fantasyName"
              validateStatus={validationErrors.fantasyName ? 'error' : ''}
              help={validationErrors.fantasyName}
              hasFeedback
            >
              <Input placeholder="Nome Fantasia" />
            </Form.Item>
            <Form.Item name="email">
              <Input
                placeholder="Email do representante legal da empresa"
              />
            </Form.Item>
            <Form.Item name="phone">
              <Input
                placeholder="Telefone do representante legal da empresa"
              />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Endereço" key="2" forceRender>
            <Form.Item name="zipCode">
              <Input
                placeholder="CEP"
                onChange={(e) => searchZipCode(e.target.value)}
              />
            </Form.Item>
            <Spin spinning={loadingSearchZipCode}>
              <Form.Item name="street">
                <Input placeholder="Logradouro" />
              </Form.Item>
              <Form.Item name="number">
                <Input placeholder="Número" />
              </Form.Item>
              <Form.Item name="complement">
                <Input placeholder="Complemento" />
              </Form.Item>
              <Form.Item name="neighborhood">
                <Input placeholder="Bairro" />
              </Form.Item>
              <Form.Item name="city">
                <Input placeholder="Cidade" />
              </Form.Item>
              <Form.Item name="state">
                <Input placeholder="Estado" />
              </Form.Item>
            </Spin>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default forwardRef(NewForm);
