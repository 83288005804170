import React, { useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Form, Input, Button, notification,
} from 'antd';
import * as Yup from 'yup';

import integracaoimage from '../../assets/integracao.jpg';
import { AuthContext } from '../../contexts/auth';
import api from '../../services/api';
import { LoginContainer, Container } from './styles';

export default function SignIn() {
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const { signIn } = useContext(AuthContext);

  const handleSubmit = useCallback((data) => {
    (async () => {
      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('E-mail inválido')
            .required('Informe um email'),
          password: Yup.string().required('Informe uma senha'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        const res = await api.post('/authenticate', data);
        const { token, user } = res.data;

        notification.success({
          message: 'Login realizado com sucesso',
        });

        signIn(token, user.id || user._id);
      } catch (err) {
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notification.error({
            message: 'Falha na Validação',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notification.error({
            message,
          });

          return;
        }

        notification.error({
          message: 'Falha ao realizar Login',
        });
      }
    })();
  }, [signIn]);

  return (
    <Container style={{
      backgroundImage: `url(${integracaoimage})`,
    }}
    >
      <LoginContainer>
        <Form size="large" onFinish={handleSubmit}>
          <Form.Item
            type="email"
            name="email"
            validateStatus={validationErrors.email ? 'error' : ''}
            help={validationErrors.email}
            hasFeedback
          >
            <Input placeholder="E-mail" />
          </Form.Item>
          <Form.Item
            name="password"
            validateStatus={validationErrors.password ? 'error' : ''}
            help={validationErrors.password}
            hasFeedback
          >
            <Input.Password placeholder="Senha" />
          </Form.Item>
          <Button type="primary" htmlType="submit" block>
            Entrar
          </Button>
          <Button
            style={{ marginTop: '10px' }}
            onClick={() => navigate('/login-usuario-letsbank')}
            block
          >
            Login Usuário Letsbank
          </Button>
        </Form>
      </LoginContainer>
    </Container>
  );
}
