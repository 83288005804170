import React, { useEffect, useRef } from 'react';

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  BankTwoTone,
  BellTwoTone,
  EditTwoTone,
  DeleteTwoTone,
} from '@ant-design/icons';
import { Space, Tooltip } from 'antd';

import useManager from '../../../hooks/manager';
import LetsbankInviteCheck from '../../LetsbankInviteCheck';
import LetsbankPix from '../../LetsbankPix';
import Table from '../../Table';
import ColumnSearch from '../../Table/Filter';
import { Action } from '../../Table/styles';
import UpdateForm from '../UpdateForm';

function AppKeyTable() {
  const updateRef = useRef();
  const letsbankInviteCheckRef = useRef();
  const letsbankPixRef = useRef();

  const {
    loading, datas, load, destroy,
  } = useManager();

  useEffect(() => {
    load('/integration');
  }, [load]);

  const columns = [
    {
      title: 'App Integração',
      dataIndex: 'appIntegration',
      key: 'appIntegration',
      sorter: (a, b) => a.appintegration.name.localeCompare(b.appintegration.name),
      sortDirections: ['descend', 'ascend'],
      filterMultiple: false,
      ellipsis: true,
      ...ColumnSearch('App Integração', 'appIntegration', 'name'),
    },
    {
      title: 'Empresa',
      dataIndex: 'company',
      key: 'company',
      sorter: (a, b) => a.company.name.localeCompare(b.company.fantasyName),
      sortDirections: ['descend', 'ascend'],
      filterMultiple: false,
      ellipsis: true,
      ...ColumnSearch('Empresa', 'company', 'fantasyName'),
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
      sorter: (a, b) => a.company.cnpj.localeCompare(b.company.cnpj),
      sortDirections: ['descend', 'ascend'],
      filterMultiple: false,
      ellipsis: true,
      width: 145,
      ...ColumnSearch('CNPJ', 'company', 'cnpj'),
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf',
      sorter: (a, b) => a.company.cpf.localeCompare(b.company.cpf),
      sortDirections: ['descend', 'ascend'],
      filterMultiple: false,
      ellipsis: true,
      width: 120,
      ...ColumnSearch('CPF', 'company', 'cpf'),
    },
    {
      title: 'Token',
      dataIndex: 'token',
      key: 'token',
      width: 350,
    },
    {
      title: 'Versão API',
      dataIndex: 'versionApi',
      key: 'versionApi',
      width: 80,
    },
    {
      title: '',
      dataIndex: 'active',
      key: 'active',
      width: 50,
      align: 'center',
      render: (text, record) => (record.active ? (
        <Tooltip placement="top" title="Integração Ativa">
          <CheckCircleTwoTone twoToneColor="#389e0d" />
        </Tooltip>
      ) : (
        <Tooltip placement="top" title="Integração Inativa">
          <CloseCircleTwoTone twoToneColor="#f5222d" />
        </Tooltip>
      )),
    },
  ];

  return (
    <>
      <UpdateForm ref={updateRef} />
      <LetsbankInviteCheck ref={letsbankInviteCheckRef} />
      <LetsbankPix ref={letsbankPixRef} />
      <Table
        data={datas}
        loading={loading}
        columns={columns}
        actions={{
          title: 'Ações',
          key: 'action',
          width: 150,
          align: 'center',
          render: (text, record) => (
            <Space size="middle">
              <Tooltip placement="top" title="Alterar">
                <Action
                  onClick={() => updateRef.current.open(record.id || record._id)}
                >
                  <EditTwoTone />
                </Action>
              </Tooltip>
              <Tooltip placement="top" title="Excluir">
                <Action
                  onClick={() => destroy(record.id || record._id, '/integration')}
                >
                  <DeleteTwoTone twoToneColor="#f00" />
                </Action>
              </Tooltip>
              {record?.appIntegration?.name === 'Letsbank' ? (
                record.letsbankInvite ? (
                  <>
                    <Tooltip placement="top" title="Respostas Letsbank">
                      <Action
                        onClick={() => letsbankInviteCheckRef.current.open(
                            record?.company?.cnpj,
                            record?.company?.cpf,
                            record?.company?.email,
                        )}
                      >
                        <BellTwoTone twoToneColor="#FF6505" />
                      </Action>
                    </Tooltip>
                    <Tooltip placement="top" title="Pix Recebidos ou Enviados">
                      <Action
                        onClick={() => letsbankPixRef.current.open(
                            record?.company?.cnpj,
                            record?.company?.cpf,
                        )}
                      >
                        <BankTwoTone twoToneColor="#ffc53d" />
                      </Action>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip placement="top" title="Link não solicitado">
                    <a
                      href="https://adminintegracoes.sistemaathos.com.br/letsbank/invite/75951f1ad1a88ef11d40a29abceb99fe252c2095f461585245679e61c2961bc3"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <CloseCircleTwoTone twoToneColor="#f5222d" />
                    </a>
                  </Tooltip>
                )
              ) : null}
            </Space>
          ),
        }}
      />
    </>
  );
}

export default AppKeyTable;
