import React, {
  useState, useCallback, forwardRef, useImperativeHandle, useRef,
} from 'react';

import {
  Form, Input, Button, notification,
} from 'antd';
import * as Yup from 'yup';

import useManager from '../../../hooks/manager';
import api from '../../../services/api';
import Modal from '../../Modal';

const UpdateForm = (_props, ref) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { update } = useManager();
  const formRef = useRef();

  const open = useCallback((openId) => {
    (async () => {
      try {
        setLoading(true);
        setShowModal(true);
        const { data } = await api.get(`/userLetsbanks/${openId}`);
        setId(openId);
        formRef.current.setFieldsValue({ name: data.name });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [formRef]);

  useImperativeHandle(ref, () => ({
    open,
  }));

  const handleSubmit = useCallback(
    () => {
      (async () => {
        try {
          const data = formRef.current.getFieldsValue();

          const schema = Yup.object().shape({
            name: Yup.string().required('Informe o nome'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          // validation passed
          update(id, '/userLetsbanks', data, () => {
            formRef.current.resetFields();
            setValidationErrors({});
            setShowModal(false);
          });
        } catch (err) {
          console.log(err);
          // Validation failed
          setValidationErrors({});
          // Validation failed
          if (err instanceof Yup.ValidationError) {
            notification.error({
              message: 'Falha na Validação',
            });
            const ve = {};
            err.inner.forEach((e) => {
              ve[e.path] = e.message;
            });
            setValidationErrors(ve);
            return;
          }

          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            notification.error({
              message,
            });

            return;
          }

          notification.error({
            message: 'Falha ao salvar',
          });
        }
      })();
    },
    [update, id],
  );

  return (
    <Modal
      title="Alterar Usuário Letsbank"
      visible={showModal}
      loading={loading}
      footer={(
        <Button type="primary" size="large" onClick={handleSubmit}>
          Alterar
        </Button>
      )}
      onClose={() => setShowModal(false)}
    >
      <Form ref={formRef} size="large">
        <Form.Item
          name="name"
          validateStatus={validationErrors.name ? 'error' : ''}
          help={validationErrors.name}
          hasFeedback
        >
          <Input placeholder="Nome" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default forwardRef(UpdateForm);
