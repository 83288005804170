import React, { useEffect } from 'react';

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InteractionTwoTone,
} from '@ant-design/icons';
import { Tooltip } from 'antd';

import useManager from '../../../hooks/manager';
import Table from '../../Table';
import ColumnSearch from '../../Table/Filter';
import { Action } from '../../Table/styles';

function CompanyTable() {
  const {
    loading, datas, load, update,
  } = useManager();

  useEffect(() => {
    load('/userLetsbankCompanies');
  }, [load]);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Nome', 'name'),
    },
    {
      title: 'Fantasia',
      dataIndex: 'fantasyName',
      key: 'fantasyName',
      sorter: (a, b) => a.fantasyName.localeCompare(b.fantasyName),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Fantasia', 'fantasyName'),
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
      sorter: (a, b) => a.cnpj.localeCompare(b.cnpj),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('CNPJ', 'cnpj'),
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf',
      sorter: (a, b) => a.cpf.localeCompare(b.cpf),
      sortDirections: ['descend', 'ascend'],
      filterMultiple: false,
      ellipsis: true,
      width: 120,
      ...ColumnSearch('CPF', 'cpf'),
    },
    {
      title: 'Link Solicitado',
      dataIndex: 'letsbankInvite',
      key: 'letsbankInvite',
      width: 96,
      align: 'center',
      render: (text, record) => (record.letsbankInvite ? (
        <Tooltip placement="top" title="Link Solicitado">
          <CheckCircleTwoTone twoToneColor="#389e0d" />
        </Tooltip>
      ) : (
        <Tooltip placement="top" title="Link não Solicitado">
          <CloseCircleTwoTone twoToneColor="#f5222d" />
        </Tooltip>
      )),
    },
    {
      title: 'Conta Criada',
      dataIndex: 'hasBankAccount',
      key: 'hasBankAccount',
      width: 96,
      align: 'center',
      render: (text, record) => (record.hasBankAccount ? (
        <Tooltip placement="top" title="Conta Criada">
          <CheckCircleTwoTone twoToneColor="#389e0d" />
        </Tooltip>
      ) : (
        <Tooltip placement="top" title="Conta não Criada">
          <CloseCircleTwoTone twoToneColor="#f5222d" />
        </Tooltip>
      )),
    },
    {
      title: 'Tem Chave Pix',
      dataIndex: 'hasKeyPix',
      key: 'hasKeyPix',
      width: 96,
      align: 'center',
      render: (text, record) => (record.hasKeyPix ? (
        <Tooltip placement="top" title="Tem Chave Pix">
          <CheckCircleTwoTone twoToneColor="#389e0d" />
        </Tooltip>
      ) : (
        <Tooltip placement="top" title="Ainda não tem Chave Pix">
          <CloseCircleTwoTone twoToneColor="#f5222d" />
        </Tooltip>
      )),
    },
  ];

  return (
    <>
      <Table
        data={datas}
        loading={loading}
        columns={columns}
        actions={{
          title: 'Ações',
          key: 'action',
          width: 100,
          align: 'center',
          render: (text, record) => (
            <Tooltip placement="top" title="Atualizar">
              <Action onClick={() => update(record.id || record._id, '/userLetsbankCompanies', {})}>
                <InteractionTwoTone />
              </Action>
            </Tooltip>
          ),
        }}
      />
    </>
  );
}

export default CompanyTable;
