import React, { useRef } from 'react';

import { Button } from 'antd';

import NewForm from '../../components/AppIntegration/NewForm';
import Table from '../../components/AppIntegration/Table';
import Page from '../../layout/Page';
import { ButtonContainer } from './styles';

function AppIntegration() {
  const newRef = useRef();

  return (
    <Page title="Gerenciador de App de Integração" selectedKey="3">
      <ButtonContainer>
        <Button type="primary" onClick={() => newRef.current.open()}>
          Cadastrar
        </Button>
      </ButtonContainer>
      <NewForm ref={newRef} />
      <Table />
    </Page>
  );
}

export default AppIntegration;
