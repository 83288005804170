import React, {
  createContext, useState, useCallback, useMemo,
} from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { notification, Modal } from 'antd';

import api from '../services/api';

export const ManagerContext = createContext();

const { confirm } = Modal;

export const ManagerProvider = ({ children }) => {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);

  const handlerError = useCallback((error, title) => {
    if (typeof error === 'string') {
      notification.error({
        message: title,
        description: error,
      });
      return;
    }

    if (error && error.response) {
      const { message } = error.response.data;
      if (message) {
        notification.error({
          message: title,
          description: message,
        });
        return;
      }
    }

    notification.error({
      message: title,
    });
  }, []);

  const load = useCallback((url, onSuccess, onError) => {
    if (url) {
      (async () => {
        try {
          setLoading(true);
          const res = await api.get(url);
          setDatas(res.data);
          /* notification.success({
            message: 'Carregado com sucesso!!!',
          }); */
          if (onSuccess) {
            onSuccess();
          }
          setLoading(false);
        } catch (error) {
          if (onError) {
            onError(error);
          }
          setLoading(false);
          handlerError(error, 'Falha ao carregar os dados');
        }
      })();
    }
  }, [handlerError]);

  const store = useCallback(
    (url, data, onSuccess, onError) => {
      if (url && data) {
        (async () => {
          try {
            setLoading(true);
            const res = await api.post(url, data);
            setDatas((d) => [res.data, ...d]);
            notification.success({
              message: 'Cadastrado com sucesso!!!',
            });
            if (onSuccess) {
              onSuccess();
            }
            setLoading(false);
          } catch (error) {
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao Cadastrar');
          }
        })();
      }
    },
    [handlerError],
  );

  const update = useCallback(
    (id, url, data, onSuccess, onError) => {
      if (id && url && data) {
        (async () => {
          try {
            setLoading(true);
            const res = await api.put(`${url}/${id}`, data);
            setDatas((d) => [
              res.data,
              ...d.filter((value) => (value.id || value._id) !== id),
            ]);
            notification.success({
              message: 'Atualizado com sucesso!!!',
            });
            if (onSuccess) {
              onSuccess();
            }
            setLoading(false);
          } catch (error) {
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao alterar');
          }
        })();
      }
    },
    [handlerError],
  );

  const destroy = useCallback(
    (id, url, onSuccess, onError) => {
      if (id && url) {
        (async () => {
          try {
            confirm({
              icon: (
                <DeleteOutlined
                  style={{
                    color: '#f00',
                    fontSize: '1.5rem',
                  }}
                />
              ),
              content: <span>Tem certeza que deseja excluir?</span>,
              async onOk() {
                try {
                  setLoading(true);
                  await api.delete(`${url}/${id}`);
                  setDatas((d) => d.filter((value) => (value.id || value._id) !== id));
                  notification.success({
                    message: 'Excluido com sucesso',
                  });
                  if (onSuccess) {
                    onSuccess();
                  }
                  setLoading(false);
                } catch (error) {
                  if (onError) {
                    onError(error);
                  }
                  setLoading(false);
                  handlerError(error, 'Falha ao excluir');
                }
              },
              okText: 'Excluir',
              okType: 'danger',
              cancelText: 'Cancelar',
              width: '50%',
              centered: true,
            });
          } catch (error) {
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao excluir');
          }
        })();
      }
    },
    [handlerError],
  );

  const value = useMemo(
    () => ({
      loading,
      datas,
      setDatas,
      load,
      store,
      update,
      destroy,
    }),
    [loading, datas, setDatas, load, store, update, destroy],
  );

  return (
    <ManagerContext.Provider value={value}>{children}</ManagerContext.Provider>
  );
};

export default ManagerProvider;
