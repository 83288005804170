import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Adm from '../pages/Adm';
import AppIntegration from '../pages/AppIntegration';
import Company from '../pages/Company';
import CompanyLetsbank from '../pages/CompanyLetsbank';
import CompanyNew from '../pages/CompanyNew';
import Home from '../pages/Home';
import Integration from '../pages/Integration';
import UserLetsbank from '../pages/UserLetsbank';

export default () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/administradores" element={<Adm />} />
    <Route path="/usuarios-letsbank" element={<UserLetsbank />} />
    <Route path="/empresas" element={<Company />} />
    <Route path="/empresas-cadastro/:token" element={<CompanyNew />} />
    <Route path="/appintegracao" element={<AppIntegration />} />
    <Route path="/integracoes" element={<Integration />} />
    <Route
      path="/letsbank/invite/:token"
      element={<CompanyLetsbank />}
    />
  </Routes>
);
