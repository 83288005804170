import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const ButtonContainer = styled.div`
  margin: 10px 0;
`;

export default Container;
