import React, { useRef } from 'react';

import { Button } from 'antd';

import NewForm from '../../components/Company/NewForm';
import Table from '../../components/Company/Table';
import Page from '../../layout/Page';
import { ButtonContainer } from './styles';

function Company() {
  const newRef = useRef();

  return (
    <Page title="Gerenciador de Empresas" selectedKey="2">
      <ButtonContainer>
        <Button type="primary" onClick={() => newRef.current.open()}>
          Cadastrar
        </Button>
      </ButtonContainer>
      <NewForm ref={newRef} />
      <Table />
    </Page>
  );
}

export default Company;
