import React, {
  useState, useEffect, useCallback, forwardRef, useRef,
} from 'react';
import { useParams } from 'react-router-dom';

import { CheckCircleTwoTone } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  notification,
  Tabs,
  Spin,
  Row,
  Col,
  Card,
} from 'antd';
import { MaskedInput } from 'antd-mask-input';
import * as Yup from 'yup';

import athosImg from '../../assets/athos.png';
import athosLabsImg from '../../assets/athoslabs.png';
import Modal from '../../components/Modal';
import api from '../../services/api';
import cep from '../../services/cep';

const CNPJ = '@cnpj';
const CPF = '@cpf';

const CompanyNew = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [successCompany, setSuccessCompany] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [token, setToken] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [cpf, setCpf] = useState('');
  const [loadingSearchZipCode, setLoadingSearchZipCode] = useState(false);
  const formRef = useRef();
  const params = useParams();

  useEffect(() => {
    setToken(params.token);
  }, [params.token]);

  useEffect(() => {
    const cnpjStorage = localStorage.getItem(CNPJ);
    const cpfStorage = localStorage.getItem(CPF);
    setCnpj(cnpjStorage);
    setCpf(cpfStorage);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = {
          cnpj: cnpj ? cnpj.replace(/[^\d]+/g, '') : '',
          cpf: cpf ? cpf.replace(/[^\d]+/g, '') : '',
        };

        if (data.cnpj.length === 14 && data.cpf.length === 11) {
          setLoading(true);
          const response = await api.get(
            `/companies/external/${token}/${data.cnpj}/${data.cpf}`,
          );

          localStorage.setItem(CNPJ, response.data.cnpj);
          localStorage.setItem(CPF, response.data.cpf);
          setLoading(false);
          setShowModal(false);
          setSuccessCompany(true);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notification.success({
            message,
          });
        }
      }
    })();
  }, [token, cnpj, cpf]);

  const searchZipCode = useCallback((zipCode) => {
    if (zipCode.length < 8) {
      formRef.current.setFieldsValue({ street: '' });
      formRef.current.setFieldsValue({ neighborhood: '' });
      formRef.current.setFieldsValue({ city: '' });
      formRef.current.setFieldsValue({ state: '' });
      return;
    }

    (async () => {
      try {
        setLoadingSearchZipCode(true);
        const { data } = await cep.get(`/${zipCode}/json`);
        formRef.current.setFieldsValue({ street: data.logradouro });
        formRef.current.setFieldsValue({ neighborhood: data.bairro });
        formRef.current.setFieldsValue({ city: data.localidade });
        formRef.current.setFieldsValue({ state: data.uf });
        console.log(data);
        setLoadingSearchZipCode(false);
      } catch (err) {
        setLoadingSearchZipCode(false);
        formRef.current.setFieldsValue({ street: '' });
        formRef.current.setFieldsValue({ neighborhood: '' });
        formRef.current.setFieldsValue({ city: '' });
        formRef.current.setFieldsValue({ state: '' });
      }
    })();
  }, []);

  const handleSubmit = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        let data = formRef.current.getFieldsValue();
        data = {
          ...data,
          cnpj: cnpj ? cnpj.replace(/[^\d]+/g, '') : '',
          cpf: cpf ? cpf.replace(/[^\d]+/g, '') : '',
        };

        const schema = Yup.object().shape({
          cnpj: Yup.string().required('Informe o CNPJ'),
          cpf: Yup.string().required('Informe o CPF'),
          name: Yup.string().required('Informe o Nome'),
          fantasyName: Yup.string().required('Informe o Nome Fantasia'),
          email: Yup.string().email().required('Informe o Email'),
          phone: Yup.string().required('Informe o Telefone'),
          zipCode: Yup.string().required('Informe o CEP'),
          street: Yup.string().required('Informe o Logradouro'),
          number: Yup.string().required('Informe o Número'),
          neighborhood: Yup.string().required('Informe o Bairro'),
          city: Yup.string().required('Informe a Cidade'),
          state: Yup.string().required('Informe o Estado'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        const response = await api.post(`/companies/external/${token}`, data);
        localStorage.setItem(CNPJ, response.data.cnpj);
        localStorage.setItem(CPF, response.data.cpf);
        setCnpj(response.data.cnpj);
        setCpf(response.data.cpf);
        formRef.current.resetFields();
        setValidationErrors({});
        setLoading(false);
        setShowModal(false);
        setSuccessCompany(true);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notification.error({
            message: 'Informe os campos necessários para cadastro',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notification.error({
            message,
          });

          if (err.response.status === 400) {
            const company = err.response.data;
            if (company) {
              localStorage.setItem(CNPJ, company.cnpj);
              localStorage.setItem(CPF, company.cpf);
              setCnpj(company.cnpj);
              setCpf(company.cpf);
              formRef.current.resetFields();
              setShowModal(false);
              setSuccessCompany(true);
            }
          }

          return;
        }

        notification.error({
          message: 'Falha ao cadastrar',
        });
      }
    })();
  }, [token, cnpj, cpf]);

  if (successCompany) {
    return (
      <>
        <Row justify="center" align="middle">
          <Col>
            <Card
              style={{ marginTop: '100px' }}
              bodyStyle={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <CheckCircleTwoTone
                style={{ fontSize: '80pt' }}
                twoToneColor="#52c41a"
              />
              <h1 style={{ marginTop: '20px' }}>
                Empresa cadastrada com sucesso.
              </h1>
            </Card>
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col>
            <img
              src={athosLabsImg}
              alt="AthosLabs"
              style={{
                width: '100%',
                maxWidth: '200px',
              }}
            />
          </Col>
          <Col>
            <img
              src={athosImg}
              alt="Athos"
              style={{
                width: '100%',
                maxWidth: '200px',
              }}
            />
          </Col>
        </Row>
        <Row justify="center" align="middle" style={{ marginTop: '50px' }}>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              setCnpj('');
              setCpf('');
              setShowModal(true);
              setSuccessCompany(false);
            }}
          >
            Cadastrar Nova Empresa
          </Button>
        </Row>
      </>
    );
  }

  return (
    <Modal
      title="Cadastro de Integração Empresa"
      visible={showModal}
      loading={loading}
      footer={(
        <Button type="primary" size="large" onClick={handleSubmit}>
          Cadastrar
        </Button>
      )}
    >
      <Form ref={formRef} size="large">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Empresa" key="1">
            <Form.Item
              validateStatus={validationErrors.cnpj ? 'error' : ''}
              help={validationErrors.cnpj}
              hasFeedback
            >
              <MaskedInput
                mask="11.111.111/1111-11"
                placeholder="CNPJ da empresa"
                value={cnpj}
                onChange={({ target: { value } }) => setCnpj(value)}
              />
            </Form.Item>
            <Form.Item
              validateStatus={validationErrors.cpf ? 'error' : ''}
              help={validationErrors.cpf}
              hasFeedback
            >
              <MaskedInput
                mask="111.111.111-11"
                placeholder="CPF do representante legal da empresa"
                value={cpf}
                onChange={({ target: { value } }) => setCpf(value)}
              />
            </Form.Item>
            <Form.Item
              name="name"
              validateStatus={validationErrors.name ? 'error' : ''}
              help={validationErrors.name}
              hasFeedback
            >
              <Input
                placeholder="Nome completo do representante legal da empresa"
                maxLength="50"
              />
            </Form.Item>
            <Form.Item
              name="fantasyName"
              validateStatus={validationErrors.fantasyName ? 'error' : ''}
              help={validationErrors.fantasyName}
              hasFeedback
            >
              <Input placeholder="Nome Fantasia" />
            </Form.Item>
            <Form.Item
              name="email"
              validateStatus={validationErrors.email ? 'error' : ''}
              help={validationErrors.email}
              hasFeedback
            >
              <Input
                placeholder="Email do representante legal da empresa"
                maxLength="50"
              />
            </Form.Item>
            <Form.Item
              name="phone"
              validateStatus={validationErrors.phone ? 'error' : ''}
              help={validationErrors.phone}
              hasFeedback
            >
              <Input
                placeholder="Telefone do representante legal da empresa"
                maxLength="11"
              />
            </Form.Item>
            <Row justify="center" align="middle">
              <Col>
                <img
                  src={athosLabsImg}
                  alt="AthosLabs"
                  style={{
                    width: '100%',
                    maxWidth: '200px',
                  }}
                />
              </Col>
              <Col>
                <img
                  src={athosImg}
                  alt="Athos"
                  style={{
                    width: '100%',
                    maxWidth: '200px',
                  }}
                />
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Endereço" key="2" forceRender>
            <Form.Item
              name="zipCode"
              validateStatus={validationErrors.zipCode ? 'error' : ''}
              help={validationErrors.zipCode}
              hasFeedback
            >
              <Input
                placeholder="CEP"
                onChange={(e) => searchZipCode(e.target.value)}
              />
            </Form.Item>
            <Spin spinning={loadingSearchZipCode}>
              <Form.Item
                name="street"
                validateStatus={validationErrors.street ? 'error' : ''}
                help={validationErrors.street}
                hasFeedback
              >
                <Input placeholder="Logradouro" />
              </Form.Item>
              <Form.Item
                name="number"
                validateStatus={validationErrors.number ? 'error' : ''}
                help={validationErrors.number}
                hasFeedback
              >
                <Input placeholder="Número" />
              </Form.Item>
              <Form.Item name="complement">
                <Input placeholder="Complemento" />
              </Form.Item>
              <Form.Item
                name="neighborhood"
                validateStatus={validationErrors.neighborhood ? 'error' : ''}
                help={validationErrors.neighborhood}
                hasFeedback
              >
                <Input placeholder="Bairro" />
              </Form.Item>
              <Form.Item
                name="city"
                validateStatus={validationErrors.city ? 'error' : ''}
                help={validationErrors.city}
                hasFeedback
              >
                <Input placeholder="Cidade" />
              </Form.Item>
              <Form.Item
                name="state"
                validateStatus={validationErrors.state ? 'error' : ''}
                help={validationErrors.state}
                hasFeedback
              >
                <Input placeholder="Estado" />
              </Form.Item>
            </Spin>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default forwardRef(CompanyNew);
