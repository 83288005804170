import { Layout } from 'antd';
import styled from 'styled-components';

const { Content: LayoutContent } = Layout;

const Content = styled(LayoutContent)`
  padding: 10px 20px;
`;

export default Content;
