import React from 'react';

import { ManagerProvider } from './contexts/manager';
import useAuth from './hooks/auth';
import AppRoutes from './routes/app.routes';
import AuthRoutes from './routes/auth.routes';
import UserLetsbankAppRoutes from './routes/userletsbank.routes';
import GlobalStyles from './styles/GlobalStyles';

import 'antd/dist/antd.css';

function App() {
  const { isAuthenticated, isUserLetsbankAuthenticated } = useAuth();

  return (
    <>
      <GlobalStyles />
      {isAuthenticated() ? (
        <ManagerProvider>
          <AppRoutes />
        </ManagerProvider>
      ) : isUserLetsbankAuthenticated() ? (
        <ManagerProvider>
          <UserLetsbankAppRoutes />
        </ManagerProvider>
      ) : (
        <AuthRoutes />
      )}
    </>
  );
}

export default App;
