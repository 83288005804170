import React, { useEffect, useRef } from 'react';

import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';

import useManager from '../../../hooks/manager';
import Table from '../../Table';
import ColumnSearch from '../../Table/Filter';
import { Action } from '../../Table/styles';
import UpdateForm from '../UpdateForm';

function AppIntegrationTable() {
  const updateRef = useRef();
  const {
    loading, datas, load, destroy,
  } = useManager();

  useEffect(() => {
    load('/appintegration');
  }, [load]);

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Nome', 'name'),
    },
  ];

  return (
    <>
      <UpdateForm ref={updateRef} />
      <Table
        data={datas}
        loading={loading}
        columns={columns}
        actions={{
          title: 'Ações',
          key: 'action',
          width: 100,
          align: 'center',
          render: (text, record) => (
            <Space size="middle">
              <Tooltip placement="top" title="Alterar">
                <Action onClick={() => updateRef.current.open(record.id || record._id)}>
                  <EditTwoTone />
                </Action>
              </Tooltip>
              <Tooltip placement="top" title="Excluir">
                <Action onClick={() => destroy(record.id || record._id, '/appintegration')}>
                  <DeleteTwoTone twoToneColor="#f00" />
                </Action>
              </Tooltip>
            </Space>
          ),
        }}
      />
    </>
  );
}

export default AppIntegrationTable;
