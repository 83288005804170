import React, { useRef } from 'react';

import { Button } from 'antd';

import NewForm from '../../components/Adm/NewForm';
import Table from '../../components/Adm/Table';
import Page from '../../layout/Page';
import { ButtonContainer } from './styles';

function Adm() {
  const newRef = useRef();

  return (
    <Page title="Gerenciador de Administradores" selectedKey="1">
      <ButtonContainer>
        <Button type="primary" onClick={() => newRef.current.open()}>
          Cadastrar
        </Button>
      </ButtonContainer>
      <NewForm ref={newRef} />
      <Table />
    </Page>
  );
}

export default Adm;
