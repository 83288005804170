import React, {
  useState, useCallback, forwardRef, useImperativeHandle,
} from 'react';

import { CheckCircleTwoTone } from '@ant-design/icons';
import {
  Button, Card, Col, Row,
} from 'antd';

import api from '../../services/api';
import Modal from '../Modal';

const LetsbankInviteCheck = (_props, ref) => {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [letsbankResponse, setLetsbankResponse] = useState([]);

  const open = useCallback((cnpj, cpf) => {
    (async () => {
      try {
        setLoading(true);
        setShowModal(true);
        const { data } = await api.get(`/letsbankIntegrations/${cnpj}/${cpf}`);
        setLetsbankResponse(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <Modal
      title="Resposta PIX do Letsbank"
      visible={showModal}
      loading={loading}
      footer={(
        <Button type="primary" size="large" onClick={() => setShowModal(false)}>
          Fechar
        </Button>
      )}
      onClose={() => setShowModal(false)}
    >
      <Row justify="center" align="middle">
        <Col>
          <Card
            style={{ marginTop: '100px' }}
            bodyStyle={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <CheckCircleTwoTone
              style={{ fontSize: '80pt' }}
              twoToneColor="#52c41a"
            />
            <h3 style={{ marginTop: '20px' }}>
              Letsbank PIX.
            </h3>
            {!loading ? (
              letsbankResponse.length ? (
                <>
                  <h6>Webhooks recebidos</h6>
                  {letsbankResponse.map((lr) => (lr.sourceId && lr.payerName ? (
                    <Row>
                      <Col>
                        {'PIX RECEBIDO -> '}
                        {'sourceId - '}
                        {lr.sourceId}
                        {' | '}
                        {lr.payerAccountType}
                        {' | '}
                        {lr.payerAgency}
                        {' | '}
                        {lr.payerDocumentNumber}
                        {' | '}
                        {lr.payerName}
                        {' | '}
                        {lr.payerNumberAccount}
                        {' | '}
                        {lr.payerNameBank}
                        {' | '}
                        {lr.amountPaid}
                        {' | '}
                        {lr.paymentDate}
                      </Col>
                    </Row>
                  ) : lr.sourceId && lr.receiverName ? (
                    <Row>
                      <Col>
                        {'PIX ENVIADO -> '}
                        {'sourceId - '}
                        {lr.sourceId}
                        {' | '}
                        {lr.receiverAccountType}
                        {' | '}
                        {lr.receiverAgency}
                        {' | '}
                        {lr.receiverDocumentNumber}
                        {' | '}
                        {lr.receiverName}
                        {' | '}
                        {lr.receiverNumberAccount}
                        {' | '}
                        {lr.receiverNameBank}
                        {' | '}
                        {lr.amountPaid}
                        {' | '}
                        {lr.paymentDate}
                      </Col>
                    </Row>
                  ) : null))}
                </>
              ) : (
                <h4>Letsbank ainda não respondeu ao convite</h4>
              )
            ) : null}
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(LetsbankInviteCheck);
