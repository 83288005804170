import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import {
  Form,
  Input,
  Button,
  notification,
  Spin,
  Checkbox,
} from 'antd';
import * as Yup from 'yup';

import isValidHttpUrl from '../../../helpers/IsValidHttpUrl';
import useManager from '../../../hooks/manager';
import api from '../../../services/api';
import Modal from '../../Modal';

const UpdateForm = (_props, ref) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [id, setId] = useState(0);
  const [appIntegrationData, setAppIntegrationData] = useState({});
  const [token, setToken] = useState('');
  const [company, setCompany] = useState();
  const [active, setActive] = useState(true);
  const [restartApi, setRestartApi] = useState(false);
  const [isDev, setIsDev] = useState(false);
  const [useLocalTunnel, setUseLocalTunnel] = useState(false);
  const [useLocalUrl, setUseLocalUrl] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingToken, setLoadingToken] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState('');
  const [linkLocalTunnel, setLinkLocalTunnel] = useState('');
  const { update } = useManager();
  const formRef = useRef();

  const open = useCallback((openId) => {
    (async () => {
      try {
        setLoading(true);
        setShowModal(true);
        const { data } = await api.get(`/integration/${openId}`);
        console.log(data);
        setId(openId);
        formRef.current.setFieldsValue({ url: data.url });
        formRef.current.setFieldsValue({
          urlAppIntegration: data.urlAppIntegration,
        });
        formRef.current.setFieldsValue({
          tokenAppIntegration: data.tokenAppIntegration,
        });
        formRef.current.setFieldsValue({
          clientIDAppIntegration: data.clientIDAppIntegration,
        });
        formRef.current.setFieldsValue({
          clientSecretAppIntegration: data.clientSecretAppIntegration,
        });
        formRef.current.setFieldsValue({
          userAppIntegration: data.userAppIntegration,
        });
        formRef.current.setFieldsValue({
          passwordAppIntegration: data.passwordAppIntegration,
        });
        setAppIntegrationData(data.appIntegration);
        setCompany(data.company._id);
        setToken(data.token);
        setUseLocalTunnel(data.useLocalTunnel);
        setUseLocalUrl(data.useLocalUrl);
        setActive(data.active);
        setRestartApi(data.restartApi);
        setIsDev(data.isDev);
        setWebhookUrl(data.webhook_url);
        setLinkLocalTunnel(isValidHttpUrl(data.link) ? data.link : '');
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [formRef]);

  useImperativeHandle(ref, () => ({
    open,
  }));

  const handleGenerateToken = useCallback(() => {
    (async () => {
      try {
        setLoadingToken(true);
        const { data } = await api.get(`/token/${company}`);
        setToken(data);
        setLoadingToken(false);
      } catch (error) {
        setLoadingToken(false);
        notification.error({
          message: 'Falha para gerar token',
        });
      }
    })();
  }, [company]);

  const handleSubmit = useCallback(() => {
    (async () => {
      try {
        const data = {
          ...formRef.current.getFieldsValue(),
          token,
          useLocalTunnel,
          useLocalUrl,
          active,
          restartApi,
          isDev,
        };

        console.log(data);
        const schema = Yup.object().shape({
          url:
            useLocalTunnel || useLocalUrl
              ? null
              : Yup.string().url('URL inválida').required('Informe a url'),
          token: Yup.string().required('Gere um token'),
          urlAppIntegration: Yup.string()
            .url('URL inválida')
            .required('Informe a url da integração'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        update(id, '/integration', data, () => {
          formRef.current.resetFields();
          setValidationErrors({});
          setToken(null);
          setUseLocalTunnel(false);
          setUseLocalUrl(true);
          setActive(true);
          setRestartApi(false);
          setIsDev(false);
          setCompany(null);
          setShowModal(false);
        });
      } catch (err) {
        console.log(err);
        // Validation failed
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notification.error({
            message: 'Falha na Validação',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notification.error({
            message,
          });

          return;
        }

        notification.error({
          message: 'Falha ao salvar',
        });
      }
    })();
  }, [update, id, token, active, useLocalTunnel, useLocalUrl, restartApi, isDev]);

  return (
    <Modal
      title="Alterar Integração"
      visible={showModal}
      loading={loading}
      footer={(
        <Button type="primary" size="large" onClick={handleSubmit}>
          Alterar
        </Button>
      )}
      onClose={() => setShowModal(false)}
    >
      <Form ref={formRef} size="large">
        <Form.Item name="useLocalTunnel">
          <Checkbox
            checked={useLocalTunnel}
            onChange={() => setUseLocalTunnel((prevState) => {
              formRef.current.setFieldsValue({ url: '' });
              setUseLocalUrl(false);
              return !prevState;
            })}
          >
            Usar Local Tunnel (Url publica será gerada no start do servidor)
          </Checkbox>
        </Form.Item>
        <Form.Item name="useLocalUrl">
          <Checkbox
            checked={useLocalUrl}
            onChange={() => setUseLocalUrl((prevState) => {
              formRef.current.setFieldsValue({ url: '' });
              setUseLocalTunnel(false);
              return !prevState;
            })}
          >
            Usar Local URL (Url da rede local do servidor será gerada no start
            do servidor)
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="url"
          validateStatus={validationErrors.url ? 'error' : ''}
          help={validationErrors.url}
          hasFeedback
        >
          <Input
            placeholder="Url API"
            readOnly={useLocalTunnel || useLocalUrl}
          />
        </Form.Item>
        <Form.Item
          name="urlAppIntegration"
          validateStatus={validationErrors.urlAppIntegration ? 'error' : ''}
          help={validationErrors.urlAppIntegration}
          hasFeedback
        >
          <Input placeholder="Url API da Integração" />
        </Form.Item>
        {appIntegrationData?.useToken && (
          <Form.Item
            name="tokenAppIntegration"
            validateStatus={validationErrors.tokenAppIntegration ? 'error' : ''}
            help={validationErrors.tokenAppIntegration}
            hasFeedback
          >
            <Input placeholder="Token ou Chave da Integração" />
          </Form.Item>
        )}
        {appIntegrationData?.useClientID && (
          <Form.Item
            name="clientIDAppIntegration"
            validateStatus={
              validationErrors.clientIDAppIntegration ? 'error' : ''
            }
            help={validationErrors.clientIDAppIntegration}
            hasFeedback
          >
            <Input placeholder="Cliente ID da Integração" />
          </Form.Item>
        )}
        {appIntegrationData?.useClientSecret && (
          <Form.Item
            name="clientSecretAppIntegration"
            validateStatus={
              validationErrors.clientSecretAppIntegration ? 'error' : ''
            }
            help={validationErrors.clientSecretAppIntegration}
            hasFeedback
          >
            <Input placeholder="Cliente Secret da Integração" />
          </Form.Item>
        )}
        {appIntegrationData?.useUserName && (
          <Form.Item
            name="userAppIntegration"
            validateStatus={validationErrors.userAppIntegration ? 'error' : ''}
            help={validationErrors.userAppIntegration}
            hasFeedback
          >
            <Input placeholder="Usuário da Integração" />
          </Form.Item>
        )}
        {appIntegrationData?.usePassword && (
          <Form.Item
            name="passwordAppIntegration"
            validateStatus={
              validationErrors.passwordAppIntegration ? 'error' : ''
            }
            help={validationErrors.passwordAppIntegration}
            hasFeedback
          >
            <Input placeholder="Senha da Integração" />
          </Form.Item>
        )}
        <Spin spinning={loadingToken}>
          <Form.Item
            validateStatus={validationErrors.token ? 'error' : ''}
            help={validationErrors.token}
            hasFeedback
          >
            <Input
              value={token}
              placeholder="Token"
              addonAfter={(
                <Button
                  type="primary"
                  size="middle"
                  onClick={handleGenerateToken}
                >
                  Gerar Token
                </Button>
              )}
              readOnly
            />
          </Form.Item>
        </Spin>
        <Form.Item name="active">
          <Checkbox
            checked={active}
            onChange={() => setActive((prevState) => !prevState)}
          >
            Ativo
          </Checkbox>
        </Form.Item>
        <Form.Item name="restartApi">
          <Checkbox
            checked={restartApi}
            onChange={() => setRestartApi((prevState) => !prevState)}
          >
            Reiniciar API
          </Checkbox>
        </Form.Item>
        <Form.Item name="isDev">
          <Checkbox
            checked={isDev}
            onChange={() => setIsDev((prevState) => !prevState)}
          >
            É uma integração de desenvolvimento
          </Checkbox>
        </Form.Item>
        {linkLocalTunnel ? (
          <>
            <strong>Link Local Tunnel: </strong>
            <br />
            {linkLocalTunnel}
            <br />
            <br />
          </>
        ) : null}
        <strong>Web Hook URL: </strong>
        <br />
        {webhookUrl}
      </Form>
    </Modal>
  );
};

export default forwardRef(UpdateForm);
