import React, {
  useState, useCallback, forwardRef, useImperativeHandle, useRef,
} from 'react';

import {
  Form, Input, Button, notification, Tabs, Checkbox,
} from 'antd';
import * as Yup from 'yup';

import useManager from '../../../hooks/manager';
import api from '../../../services/api';
import Modal from '../../Modal';

const UpdateForm = (_props, ref) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [id, setId] = useState(0);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { update } = useManager();
  const formRef = useRef();

  const open = useCallback((openId) => {
    (async () => {
      try {
        setLoading(true);
        setShowModal(true);
        const { data } = await api.get(`/company/${openId}`);
        setId(openId);
        formRef.current.setFieldsValue({ cnpj: data.cnpj });
        formRef.current.setFieldsValue({ cpf: data.cpf });
        formRef.current.setFieldsValue({ name: data.name });
        formRef.current.setFieldsValue({ fantasyName: data.fantasyName });
        formRef.current.setFieldsValue({ email: data.email });
        formRef.current.setFieldsValue({ phone: data.phone });
        formRef.current.setFieldsValue({ zipCode: data.zipCode });
        formRef.current.setFieldsValue({ street: data.street });
        formRef.current.setFieldsValue({ number: data.number });
        formRef.current.setFieldsValue({ complement: data.complement });
        formRef.current.setFieldsValue({ neighborhood: data.neighborhood });
        formRef.current.setFieldsValue({ city: data.city });
        formRef.current.setFieldsValue({ state: data.state });
        setActive(data.active);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [formRef]);

  useImperativeHandle(ref, () => ({
    open,
  }));

  const handleSubmit = useCallback(
    () => {
      (async () => {
        try {
          const data = {
            ...formRef.current.getFieldsValue(),
            active,
          };

          const schema = Yup.object().shape({
            cnpj: Yup.string().required('Informe o CNPJ'),
            name: Yup.string().required('Informe o Nome'),
            fantasyName: Yup.string().required('Informe o Nome Fantasia'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          // validation passed
          update(id, '/company', data, () => {
            formRef.current.resetFields();
            setValidationErrors({});
            setShowModal(false);
          });
        } catch (err) {
          console.log(err);
          // Validation failed
          setValidationErrors({});
          // Validation failed
          if (err instanceof Yup.ValidationError) {
            notification.error({
              message: 'Falha na Validação',
            });
            const ve = {};
            err.inner.forEach((e) => {
              ve[e.path] = e.message;
            });
            setValidationErrors(ve);
            return;
          }

          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            notification.error({
              message,
            });

            return;
          }

          notification.error({
            message: 'Falha ao salvar',
          });
        }
      })();
    },
    [update, id, active],
  );

  return (
    <Modal
      title="Alterar Empresa"
      visible={showModal}
      loading={loading}
      footer={(
        <Button type="primary" size="large" onClick={handleSubmit}>
          Alterar
        </Button>
      )}
      onClose={() => setShowModal(false)}
    >
      <Form ref={formRef} size="large">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Empresa" key="1">
            <Form.Item
              name="cnpj"
              validateStatus={validationErrors.cnpj ? 'error' : ''}
              help={validationErrors.cnpj}
              hasFeedback
            >
              <Input placeholder="CNPJ da empresa" maxLength={14} />
            </Form.Item>
            <Form.Item name="cpf">
              <Input
                placeholder="CPF do representante legal da empresa"
                maxLength={11}
              />
            </Form.Item>
            <Form.Item
              name="name"
              validateStatus={validationErrors.name ? 'error' : ''}
              help={validationErrors.name}
              hasFeedback
            >
              <Input placeholder="Nome completo do representante legal da empresa" />
            </Form.Item>
            <Form.Item
              name="fantasyName"
              validateStatus={validationErrors.fantasyName ? 'error' : ''}
              help={validationErrors.fantasyName}
              hasFeedback
            >
              <Input placeholder="Nome Fantasia" />
            </Form.Item>
            <Form.Item name="email">
              <Input placeholder="Email do representante legal da empresa" />
            </Form.Item>
            <Form.Item name="phone">
              <Input placeholder="Telefone do representante legal da empresa" />
            </Form.Item>
            <Form.Item name="active">
              <Checkbox
                checked={active}
                onChange={() => setActive((prevState) => !prevState)}
              >
                Ativo
              </Checkbox>
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Endereço" key="2" forceRender>
            <Form.Item name="zipCode">
              <Input placeholder="CEP" />
            </Form.Item>
            <Form.Item name="street">
              <Input placeholder="Logradouro" />
            </Form.Item>
            <Form.Item name="number">
              <Input placeholder="Número" />
            </Form.Item>
            <Form.Item name="complement">
              <Input placeholder="Complemento" />
            </Form.Item>
            <Form.Item name="neighborhood">
              <Input placeholder="Bairro" />
            </Form.Item>
            <Form.Item name="city">
              <Input placeholder="Cidade" />
            </Form.Item>
            <Form.Item name="state">
              <Input placeholder="Estado" />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default forwardRef(UpdateForm);
