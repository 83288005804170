import React, {
  useState, useCallback, forwardRef, useImperativeHandle, useRef,
} from 'react';

import {
  Form, Input, Button, notification,
} from 'antd';
import * as Yup from 'yup';

import useManager from '../../../hooks/manager';
import Modal from '../../Modal';

const NewForm = (_props, ref) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { store } = useManager();
  const formRef = useRef();

  useImperativeHandle(ref, () => ({
    open: () => setShowModal(true),
  }));

  const handleSubmit = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const data = formRef.current.getFieldsValue();

        const schema = Yup.object().shape({
          name: Yup.string().required('Informe o nome'),
          email: Yup.string()
            .email('Email inválido')
            .required('Informe o email'),
          password: Yup.string()
            .min(6, 'Informe pelo menos 6 caracteres')
            .required('Informe a senha'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        store('/adm', data, () => {
          formRef.current.resetFields();
          setValidationErrors({});
          setShowModal(false);
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notification.error({
            message: 'Falha na Validação',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notification.error({
            message,
          });

          return;
        }

        notification.error({
          message: 'Falha ao cadastrar',
        });
      }
    })();
  },
  [store]);

  return (
    <Modal
      title="Novo Administrador"
      visible={showModal}
      loading={loading}
      footer={(
        <Button type="primary" size="large" onClick={handleSubmit}>
          Cadastrar
        </Button>
      )}
      onClose={() => setShowModal(false)}
    >
      <Form ref={formRef} size="large">
        <Form.Item
          name="name"
          validateStatus={validationErrors.name ? 'error' : ''}
          help={validationErrors.name}
          hasFeedback
        >
          <Input placeholder="Nome" />
        </Form.Item>
        <Form.Item
          type="email"
          name="email"
          validateStatus={validationErrors.email ? 'error' : ''}
          help={validationErrors.email}
          hasFeedback
        >
          <Input placeholder="E-mail" />
        </Form.Item>
        <Form.Item
          name="password"
          validateStatus={validationErrors.password ? 'error' : ''}
          help={validationErrors.password}
          hasFeedback
        >
          <Input.Password placeholder="Senha" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default forwardRef(NewForm);
