import React, {
  useState, useEffect, createContext, useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getToken, setToken, setIdKey, logout,
} from '../services/auth';
import {
  getToken as userLetsbankGetToken,
  setToken as userLetsbankSetToken,
  setIdKey as userLetsbankSetIdKey,
  logout as userLetsbankLogout,
} from '../services/userletsbankauth';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [userToken, setUserToken] = useState(null);
  const [userLetsbankToken, setUserLetsbankToken] = useState(null);

  const value = useMemo(
    () => ({
      isAuthenticated: () => !!userToken,
      isUserLetsbankAuthenticated: () => !!userLetsbankToken,
      signIn: (token, idKey) => {
        setToken(token);
        setIdKey(idKey);
        setUserToken(token);
        navigate('/');
      },
      userLetsbankSignIn: (token, idKey) => {
        userLetsbankSetToken(token);
        userLetsbankSetIdKey(idKey);
        setUserLetsbankToken(token);
        navigate('/');
      },
      signOut: () => {
        logout();
        navigate('/');
        setUserToken(null);
      },
      userLetsbankSignOut: () => {
        userLetsbankLogout();
        navigate('/');
        setUserLetsbankToken(null);
      },
    }),
    [userToken, userLetsbankToken, navigate],
  );

  useEffect(() => {
    setUserToken(getToken());
  }, []);

  useEffect(() => {
    setUserLetsbankToken(userLetsbankGetToken());
  }, []);

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
