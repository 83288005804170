import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Company from '../pages/UserLetsbankCompany';

export default () => (
  <Routes>
    <Route path="/" element={<Company />} />
    <Route path="/empresas" element={<Company />} />
    <Route path="*" element={<Company />} />
  </Routes>
);
