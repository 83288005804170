/* eslint-disable no-param-reassign */
import axios from 'axios';

import { getToken, getIdKey } from './auth';
import { getToken as userLetsbankGetToken, getIdKey as userLetsbankIdkey } from './userletsbankauth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async (config) => {
  const token = getToken() || userLetsbankGetToken();
  const id = getIdKey() || userLetsbankIdkey();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.id = id;
  }
  return config;
});

export default api;
