import { useContext } from 'react';

import { ManagerContext } from '../contexts/manager';

const useManager = () => {
  const context = useContext(ManagerContext);

  if (!context) {
    throw new Error('useManager must be used within a ManagerProvider');
  }

  return context;
};

export default useManager;
